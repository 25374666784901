.modal {}

.modal-backdrop.in {
	background-color: #fff;
	opacity: 0.9;
}

.modal-dialog {
	max-width: 900px;
	width: 100%;
	margin: 10% auto;
	@include respond-to('large') {
		margin: 8% auto;
	}
	@include respond-to('extra-small') {
		margin: 0 auto;
	}
}

.modal-content {
	border-radius: 0;
	border: none;
	box-shadow: 0 5px 35px 0 rgba(0, 0, 0, .3);
}

.modal-propos {
	padding: 0px 90px 15px;
	@include respond-to('medium') {
		padding: 5px 50px 10px;
	}
	@include respond-to('extra-small') {
		padding: 25px 10px 40px;
	}
	&__title {
		text-align: center;
		color: $text;
		font-size: 30px;
		font-weight: 900;
		@include respond-to('medium') {
			font-size: 25px;
		}
	}
	&__hr {
		margin: 15px 0;
		border-top: 1px solid #dcdcdc;
		@include respond-to('large') {
			margin: 10px 0;
		}
	}
	&__items {}
}

.prop-item {
	padding: 10px;
	max-height: 480px;
	width: 100%;
	min-width: 190px;
	overflow: hidden;
}
.headerpop{
	text-align: right;
	margin-top: 4%;
	padding: 0 75px;
}
.h4popup{
	padding-#{$left}: 15px; ;
}
.modal-close {
	position: absolute;
	top: 5px;
	#{$left}: 15px;
	color: $text;
	font-size: 40px;
	line-height: 1;
	cursor: pointer;
	@include respond-to('extra-small') {
		top: 0px;
		#{$left}: 10px;
		font-size: 35px;
	}
}

.prop-slider {
	& .modal-propos__item {
		display: none;
		&:first-child {
			display: block;
		}
		&.slick-slide {
			display: block;
		}
	}

	& .slick-arrow {
		width: 19px;
		height: 41px;
	}
	& .slick-prev {
		#{$right}: -25px;
		#{$left}: auto;
		@include respond-to('extra-small') {
			#{$right}: -10px;
		}
		&::before {
			content: if-rtl(url('../images/arr-r.png'), url('../images/arr-l.png'));
		}
	}
	& .slick-next {
		#{$left}: -25px;
		#{$right}: auto;
		@include respond-to('extra-small') {
			#{$left}: -10px;
		}
		&::before {
			content: if-rtl(url('../images/arr-l.png'), url('../images/arr-r.png'));
		}
	}
}

.prop-item {
	& .book-item__img-wr {
		&:hover {
			.ribbon-box {
				opacity: 1;
			}
		}
	}
	& .book-item__img {
		margin-bottom: 10px;
	}
	& .book-item__prices {
		margin-top: 8px;
		padding: 8px 0;
	}
	& .book-item-btn {
		height: 40px;
		max-width: 150px;
		width: 100%;
		box-shadow: 0 0 5px 1px rgba($color: #000000, $alpha: 0.5);
		font-size: 18px;
		font-weight: 900;
		color: #fff;
		background-color: $accent;
		border: none;
		&:active {
			box-shadow: 0 0 2px 1px rgba($color: #000000, $alpha: 0.2);
		}
	}
}